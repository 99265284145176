module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-NMQRZ22W","includeInDevelopment":false,"defaultDataLayer":null,"routeChangeEventName":"gatsby-route-change","enableWebVitalsTracking":false,"selfHostedOrigin":"https://www.googletagmanager.com","selfHostedPath":"gtm.js"},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Johnson & Johnson: Patient centred innovation with Science & Care","short_name":"Johnson & Johnson","start_url":"/","background_color":"#eb1701","theme_color":"#eb1701","display":"standalone","icon":"src/images/icon.png","icons":[{"src":"src/images/android-chrome-36x36.png","sizes":"36x36","type":"image/png","purpose":"any maskable"},{"src":"src/images/android-chrome-48x48.png","sizes":"48x48","type":"image/png","purpose":"any maskable"},{"src":"src/images/android-chrome-72x72.png","sizes":"72x72","type":"image/png","purpose":"any maskable"},{"src":"src/images/android-chrome-96x96.png","sizes":"96x96","type":"image/png","purpose":"any maskable"},{"src":"src/images/android-chrome-144x144.png","sizes":"144x144","type":"image/png","purpose":"any maskable"},{"src":"src/images/android-chrome-192x192.png","sizes":"192x192","type":"image/png","purpose":"any maskable"},{"src":"src/images/android-chrome-256x256.png","sizes":"256x256","type":"image/png","purpose":"any maskable"},{"src":"src/images/android-chrome-384x384.png","sizes":"384x384","type":"image/png","purpose":"any maskable"},{"src":"src/images/android-chrome-512x512.png","sizes":"512x512","type":"image/png","purpose":"any maskable"}],"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"3ce543083d74f44072c2cf4c344663c8"},
    },{
      plugin: require('../node_modules/gatsby-plugin-facebook-pixel/gatsby-browser.js'),
      options: {"plugins":[],"pixelId":"1503793220240664"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
